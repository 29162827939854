<template>
<div class="order-container">
  <el-row>
    <div class="header"></div>
    <el-col :span="24">
      <el-result :icon="orderForm.paySuccessStatus ?'success' : 'warning' " :title="orderForm.paySuccessStatus ? '支付成功' : '支付异常'">
        <template slot="extra">
          <div class="order-info">
            <div class="order-item">商品订单:  <strong>{{orderForm.orderId}}</strong></div>
            <div class="order-item">支付金额:  <strong>{{orderForm.money}}</strong></div>
            <div class="order-item">购买时间:  <strong>{{orderForm.createTime}}</strong></div>
          </div>
          <div class="warning-info" v-if="!orderForm.paySuccessStatus">
            *支付异常，请联系管理员
          </div>
<!--          <el-button type="primary" size="medium" style="margin-top: 30px" @click="hanldeBack">返回</el-button>-->
        </template>
      </el-result>
    </el-col>
  </el-row>
</div>
</template>

<script>
import { info } from '@/api/detail.js'
import dayjs from 'dayjs'

export default {
  name: 'order',
  data () {
    return {
      orderForm: ''
    }
  },
  created () {
    this.handleInfo()
  },
  methods: {
    async handleInfo () {
      const orderId = this.$route.query.orderId
      const { data, code } = await info({ orderId })
      if (code === 20000) {
        data.createTime = dayjs(data.createTime).format('YYYY-MM-DD')
        this.orderForm = data
      }
    },
    hanldeBack () {
      const brandId = this.$route.query.brandId
      this.$router.push({ path: `/home/${brandId}` })
    }
  }
}
</script>

<style scoped lang="less">
.order-item {
  line-height: 24px;
  text-align: left;
  font-size: 14px;
  color: #5e6d82;
  margin-top: 10px;
}
.header {
  height: 50px;
  background: #efefef;
}
.warning-info {
  margin-top: 30px;
  color: #E6A23C;
  font-size: 14px;
  text-align: left;
}
</style>
