<template>
  <div class="detail">
    <div class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="indexUrl">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{params.productname}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8"><div ><el-image class="pro-img" :src="params.imgurl"></el-image></div></el-col>
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16"><div class="desc">
          <h3 class="pro-title">{{params.productname}}</h3>
          <div class="price">
            <div>市场价：<s>￥{{params.marketprice}}</s></div>
            <div>价格：<span class="redbold">￥<b id="ym-price">{{params.saleprice}}</b></span></div>
            <div style="font-size: 18px;margin-top: 20px;">实付款：<span class="payPrice">￥<b>{{orderPrice}}</b></span></div>
          </div>
          <div class="deliver">
            <div style="color: #c0c0c0;">库存：<span><b>999+</b></span></div>
          </div>
          <div class="pro-number">
            <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
              <el-form-item>
              <el-input-number @change="handleNum" size="medium" v-model="ruleForm.number" :precision="0" :min="1" :max="1000" label="描述文字"></el-input-number>
              </el-form-item>
              <el-form-item>
                <el-input class="phoneInput" v-model.number="ruleForm.phone" maxLength="11" autocomplete="off">
                  <template slot="prepend">手机号码</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="备注"
                  v-model="ruleForm.desc">
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="medium" class="buyBtn" @click="handleApply">立即购买</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        </el-col>
      </el-row>
    </div>
    <div class="pro-detail">
      <el-tabs>
        <el-tab-pane label="商品详情" >
          <div>{{params.introduce}}</div>
        </el-tab-pane>
        <el-tab-pane label="免责声明">
          <div>{{params.introduce}}</div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="qrcode">
      <div style="text-align: center; font-size: 16px;color: #666666;margin-bottom: 10px;">扫码联系客服</div>
      <el-image src="https://qrimg.jd.com/https%3A%2F%2Fitem.m.jd.com%2Fproduct%2F100022067320.html%3Fpc_source%3Dpc_productDetail_100022067320-118-1-4-2.png?ltype=0"></el-image>
    </div>
    <el-dialog
      :title="orderNo"
      :visible.sync="dialogVisible"
      width="30%"
      >
      <div style="text-align: center;">
        <el-image :src="payPic"></el-image>
        <div style="color: #de342f; margin-top: 30px;font-weight: bolder">* 务必截图保存！！！</div>
      </div>
      <span slot="footer" class="dialog-footer">
<!--          <el-button @click="dialogVisible = false">取 消</el-button>-->
          <el-button type="primary" @click="dialogVisible = false">已扫码支付</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import { Message } from 'element-ui'
import { pay, confirm } from '@/api/detail.js'
import payPic from '../assets/WechatIMG433.png'

export default {
  data () {
    return {
      indexUrl: '/home/1',
      ruleForm: {
        number: ''
      },
      payPic,
      params: '',
      orderPrice: 0,
      dialogVisible: false,
      orderNo: '111111'
    }
  },
  created () {
    const query = this.$route.query
    this.indexUrl = `/home/${this.$route.params.brandId}`
    this.params = query
    this.orderPrice = query.saleprice
  },
  methods: {
    async handleApply () {
      const params = {
        brandId: this.$route.params.brandId,
        buyCount: this.ruleForm.number,
        productId: this.params.productId,
        phone: this.ruleForm.phone,
        desc: this.ruleForm.desc
      }
      if (!this.ruleForm.phone) {
        Message({
          message: '手机号码不能为空！',
          type: 'error',
          duration: 5 * 1000
        })
        return false
      }
      const res = await pay(params)
      this.dialogVisible = true
      this.orderNo = '订单编号: ' + res.data
      // let div = document.querySelector('#payDiv')
      // // 如果页面已经存在payDiv，则只要修改payDiv的innerHTML就可以了，否则提交的表单永远是第一个表单。
      // if (!div) {
      //   div = document.createElement('div')
      //   div.id = 'payDiv'
      //   document.body.appendChild(div)
      // }
      // div.innerHTML = res
      // document.querySelector('#payDiv > form').target = '_blank'
      // document.querySelector('#payDiv > form').submit()
    },
    async handleNum () {
      const number = this.ruleForm.number
      const { data, code } = await confirm({ buyCount: number, productId: this.params.productId })
      if (code === 20000) {
        this.orderPrice = data.orderPrice
      }
    }
  }
}
</script>
<style lang="less">
.detail {
  width: 1200px;
  margin: 0 auto;
  .nav {
   padding: 24px;
    background-color: #f6f6f6;
  }
  .content {
    padding: 24px;
  }
}
.phoneInput {
  width: 50%;
}
@media (max-width: 992px) {
  .detail {
    width:100%;
  }
  .nav {
    display: none;
  }
  .desc {
    padding-left: 0 !important;
  }
  .pro-title {
    margin-top: 24px;
  }
  .phoneInput {
    width: 100%;
  }
}
.pro-img {
  border-radius: 4px;
}
.desc {
  padding-left: 30px;
}
.price{
  margin: 20px 0 20px;
  font-size: 12px;
  line-height: 30px;
  padding: 10px;
  background-color: #FFF2E8;
  .redbold {
    font-size: 20px;
    color: #de342f;
    font-weight: bold;
  }
  .payPrice {
    font-size: 26px;
    color: #de342f;
    font-weight: bold;
  }
}
.pro-number {
  padding: 20px 0 26px;
  overflow: hidden;
  clear: both;
}
.pro-detail {
  padding: 24px;
  text-align: center;
}
.deliver {
  font-size: 12px;
}
.buyBtn {
  border-radius: 0;
  background-color: #df3033;
  color: #fff;
  border-color: #df3033;
}
.buyBtn:hover,.buyBtn:active,.buyBtn:focus{
  background-color: #df3033;
  border-color: #df3033;
}
.el-tabs__item.is-active {
  color: #df3033;
}
.el-tabs__active-bar {
  background-color:#df3033 ;
}
.el-tabs__item:hover {
  color: #df3033;
}
.el-breadcrumb__inner.is-link:hover,.el-breadcrumb__inner a:hover {
  color: #df3033;
  cursor: pointer
}
.el-input-number__decrease:hover,.el-input-number__increase:hover {
  color: #df3033
}

.el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),.el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
  border-color: #df3033
}
.el-input__inner:focus {
  border-color: #df3033;
}
.qrcode {
  position: fixed;
  right: 20px;
  top: 10%;
}
</style>
