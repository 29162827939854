import request from '../utils/request'
export function pay (params) {
  return request({
    url: '/order/create',
    method: 'GET',
    params
  })
}
export function confirm (data) {
  return request({
    url: '/order/confirm',
    method: 'POST',
    data
  })
}
export function info (params) {
  return request({
    url: '/order/info',
    method: 'GET',
    params
  })
}
